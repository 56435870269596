import React from 'react'
import Paginate from 'react-paginating'

export default function Pagination(props) {
  let {
    total,
    limit,
    current,
    pageCount = 5,
    getLinkProps = () => ({}),
    className,
    bordered = false,
    ...rest
  } = props

  total = total || 0
  limit = limit ? parseInt(limit, 10) : 20

  const currentPage = current ? parseInt(current, 10) : 1
  const min = currentPage * limit - limit + 1
  const max = Math.min(currentPage * limit, total)

  return (
    <Paginate
      total={total}
      limit={limit}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps
      }) => {
        const totalsElement = totalPages ? (
          <div className="order-last sm:order-first mt-4 sm:mt-0">
            Results {min} - {max} out of {total}
          </div>
        ) : null

        const pageLinksElement = (
          <div className="flex justify-center sm:justify-end">
            {hasPreviousPage && (
              <a
                key="prev"
                children="Prev"
                {...getLinkProps({ page: previousPage, prev: true })}
                className={[
                  'px-4 py-2 mx-1 shadow-sm bg-white rounded-sm cursor-pointer',
                  bordered && 'border border-gray-300',
                ]}
              />
            )}
            {pages.length > 1 && pages.map(page => (
              <a
                key={page}
                children={page}
                className={[
                  'px-4 py-2 mx-1 shadow-sm rounded-sm bg-white',
                  !hasPreviousPage && 'ml-0',
                  currentPage === page ? 'text-blue' : 'cursor-pointer',
                  bordered && 'border border-gray-300',
                ]}
                {...getLinkProps({ page: page, current: currentPage === page })}
              />
            ))}
            {hasNextPage && (
              <a
                key="next"
                children="Next"
                {...getLinkProps({ page: nextPage, next: true })}
                className={[
                  'px-4 py-2 mx-1 shadow-sm bg-white rounded-sm cursor-pointer',
                  bordered && 'border border-gray-300',
                ]}
              />
            )}
          </div>
        )

        return (
          <div className={[
            className,
            'flex flex-col sm:flex-row justify-between items-center text-gray-600 leading-snug',
            'text-center sm:text-left',
          ]}>
            {totalsElement}
            {pageLinksElement}
          </div>
        )
      }}
    </Paginate>
  )
}
