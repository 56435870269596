import { useState, useEffect, useMemo } from 'react'
import { useClient } from 'jsonapi-react'
import { useHistory } from 'react-router-dom'
import useSessionStorage from 'lib/useSessionStorage'
import { parse } from 'qs'
import useLoginToken from './useLoginToken'

export default function useCreateSession() {
  const client = useClient()
  const history = useHistory()

  const queryParams = useMemo(() => (
    history.location.search ? parse(history.location.search.substr(1)) : {}
  ), [history.location.search])

  const [token, setToken, removeToken] = useLoginToken()

  const [sessionData, setSessionData] = useSessionStorage('session_data', {
    caterer_id: queryParams.caterer_id,
    is_admin: !!(queryParams.caterer_id && queryParams.session_token),
  })

  const [state, setState] = useState({
    user: null,
    // Available caterers for the user
    caterers: null,
    // The selected caterer
    caterer: null,
    isLoading: true,
    isLoggedIn: false,
  })

  const destroy = () => {
    removeToken()
    setSessionData(undefined)
    client.removeHeader('Authorization')

    setState({
      user: null,
      caterers: null,
      caterer: null,
      isLoading: false,
      isLoggedIn: false,
    })
  }

  const refetch = () => {

    const isAdmin = sessionData?.is_admin || false
    const catererId = sessionData?.caterer_id

    client.addHeader(
      'Authorization',
      `Bearer ${token}`
    )
    client.fetch(['users/me', {
      include: [
        'caterers',
        'caterers.logo',
        'caterers.photos',
        'caterers.settings',
        isAdmin && 'admin',
      ].filter(Boolean),
      extra_fields: {
        caterers: [
          'has_expired_coi',
        ],
        caterer_settings: [
          'tipalti_iframe_url',
        ]
      }
    }], {
      force: true,
    }).then(async (result) => {
      if (result.error) {
        client.removeHeader('Authorization')

        setState({
          ...state,
          isLoading: false,
          isLoggedIn: false,
        })
      } else {
        const state = {
          user: result.data,
          isLoading: true,
          isLoggedIn: true,
        }

        if (isAdmin && catererId) {
          state.caterer = await client.fetch(['caterers', catererId, {
            extra_fields: {
              caterers: 'has_expired_coi',
              caterer_settings: 'tipalti_iframe_url',
            },
            include: [
              'logo', 'photos', 'settings'
            ]
          }]).then(res => res.data)
        } else if (catererId) {
          state.caterer = result.data.caterers?.find(c => c.id === catererId)
        }

        state.caterers = result.data.caterers || [state.caterer].filter(Boolean)

        if (!state.caterer) {
          state.caterer = result.data.caterers?.[0]
        }

        if (state.caterer) {
          state.isLoading = false
          setState(state)
          setSessionData({
            caterer_id: state.caterer.id,
            is_admin: isAdmin,
          })
        } else {
          destroy()
          history.push('/login')
        }
      }
    })
  }

  useEffect(() => {
    if (queryParams.session_token) {
      history.replace(history.location.path)

      if (queryParams.session_token !== token) {
        setToken(queryParams.session_token)
        return undefined
      }
    }

    if (token) {
      refetch()
    } else if (state.user) {
      setState({
        ...state,
        isLoggedIn: false,
      })
    } else {
      setTimeout(() => setState({
        isLoading: false
      }), 600)
    }
  }, [token])

  useEffect(() => {
    return client.subscribe(action => {
      if (action.error?.status === '401') {
        destroy()
      }
    })
  }, [])

  return useMemo(() => ({
    ...state,
    refresh: refetch,
    token,
    setToken,
    destroy,
    setCaterer(caterer) {
      setSessionData(prev => ({
        ...prev,
        caterer_id: caterer.id,
      }))
      setState(prev => ({
        ...prev,
        caterer,
      }))
    }
  }), [state])
}
