import { useState, useEffect } from 'react'
import { usePostHog } from './usePostHog'

export function useFeatureFlagEnabled(flag, { autoLoad } = {}) {
  const { posthog, onFeatureFlags } = usePostHog()

  const [featureEnabled, setFeatureEnabled] = useState(
    posthog.isFeatureEnabled(flag)
  )

  useEffect(() => {
    return onFeatureFlags(() => {
      setFeatureEnabled(posthog.isFeatureEnabled(flag))
    }, autoLoad)
  }, [posthog, flag])

  return featureEnabled
}

