import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import posthog from 'posthog-js'

const TrackableLink = ({ to, children, title, ...props }) => {
  const history = useHistory()

  // Determine the link text for tracking
  const linkText =
    title || (typeof children === 'string' ? children : 'Non-text link')

  const handleClick = event => {
    // Prevent default behavior
    event.preventDefault()


    // Track the click event in PostHog
    posthog.capture('link_clicked', { path: to, linkText })

    // Use history.push to navigate after tracking
    history.push(to)
  }

  return (
    <Link to={to} {...props} onClick={handleClick}>
      {children}
    </Link>
  )
}

export default TrackableLink
