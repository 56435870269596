import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useContext,
  createContext,
} from 'react'
import { createPortal } from 'react-dom'
import { motion as Motion, AnimatePresence } from 'framer-motion'
import { X, CheckCircle, AlertCircle, AlertTriangle } from 'react-feather'
import styles from './Notification.module.css'

const context = createContext([])

const createId = () => (
  ((new Date()).getTime() + Math.floor(Math.random() * 10000)).toString(36)
)

export function useNotify() {
  return useContext(context)
}

export function NotificationProvider({ children }) {
  const ref = useRef()

  useEffect(() => {
    ref.current = document.body
  }, [])

  const [state, setState] = useState([])

  const methods = useMemo(() => ({
    show(config) {
      const id = createId()

      const remove = () => (
        setState(p => p.filter(n => n.id !== id))
      )

      setState(p => [...p, { id, remove, config }])

      return remove
    },
    clear() {
      setState([])
    },
  }), [])

  const notifications = ref.current ? (
    createPortal(
      <Notifications notifications={state} />,
      ref.current
    )
  ) : null

  return (
    <context.Provider value={methods}>
      {children}
      {notifications}
    </context.Provider>
  )
}

export function Notifications({ notifications }) {
  return (
    <div className={styles.container}>
      <AnimatePresence initial={false}>
        {notifications.map(n => (
          <Motion.div
            key={n.id}
            positionTransition
            initial={{ opacity: 0, y: -50, scale: 0.9 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -20, scale: 0.9, transition: { duration: 0.2 } }}
          >
            <Notification
              dismiss={n.remove}
              {...n.config}
            />
          </Motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}

const icons = {
  success: CheckCircle,
  error: AlertCircle,
  alert: AlertTriangle,
}

export function Notification(props) {
  const {
    dismiss,
    duration = 3000,
    theme = 'alert',
    title,
    message,
    icon: Icon = icons[theme],
    ...rest
  } = props

  useEffect(() => {
    if (duration) {
      setTimeout(() => dismiss(), duration)
    }
  }, [])

  return (
    <div
      onClick={() => {
        dismiss()
      }}
      className={[
        styles.notification,
        styles[theme],
      ]}
      >
      {Icon && (
        <div className={styles.icon}>
          <Icon size={30} />
        </div>
      )}
      <div className={styles.text}>
        {title && (
          <div className="text-xl leading-none mb-1">
            {title}
          </div>
        )}
        {message && (
          <div className="leading-tight">
            {message}
          </div>
        )}
      </div>
    </div>
  )
}
