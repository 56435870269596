import React, { useMemo } from 'react'
import { format } from 'date-fns'
import styles from './CateringAgreement.module.css'

export default function CateringAgreement(props) {
  const { agreement, maxHeight = '600px' } = props

  const text = useMemo(() => {
    return agreement.text.replace(
      '*|EFFECTIVE_DATE|*',
      format(new Date(agreement.effective_date), 'MM/dd/y')
    )
  }, [agreement])

  return (
    <div
      className="overflow-auto border border-gray-300"
      style={{ maxHeight }}
    >
      <div className="bg-gray-200 p-3">
        <div
          dangerouslySetInnerHTML={{
            __html: text
          }}
          className={[
            styles.container,
            'bg-white p-6 border border-gray-300',
          ]}
        />
      </div>
    </div>
  )
}
