export default {
  fields: {
    signed_at: {
      type: 'date',
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    caterer: 'caterers',
    agreement: 'agreements'
  }
}
