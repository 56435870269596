import React, { useState } from 'react'
import logo from 'assets/svg/logo-no-circle.svg'
import { Link, Redirect } from 'react-router-dom'
import { useMutation } from 'jsonapi-react'
import { useNotify } from 'app/theme/Notification'
import { TrackableLink } from 'app/theme'
import Submit from 'app/theme/forms/Submit'
import useEventTracker from 'lib/useEventTracker'
import posthog from 'posthog-js'
import env from 'env'

export default function LoginPage({ session }) {
  const [fields, setFields] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState(null)
  const [submit, { client, isLoading }] = useMutation('authorizations')
  const tracker = useEventTracker()
  const notify = useNotify()

  const handleSubmit = (e) => {
    e.preventDefault()

    posthog.capture('login_attempted')

    submit({ ...fields, scope: 'caterer' }).then(result => {
      if (result.error) {
        notify.show({
          theme: 'error',
          title: 'Error',
          message: result.error.message,
        })
        // Track the failed login attempt
        posthog.capture('login_failed')
      } else if (result.errors) {
        setErrors(result.errors)
        // Track the validation errors
        posthog.capture('login_validation_errors')
      } else {
        const expires = new Date(result.data.expires_at)
        session.setToken(result.data.token, { expires })
        tracker.event('Login')
        posthog.capture('login_successful')
      }
    })
  }

  if (session.isLoggedIn) {
    posthog.identify(
      session.user?.id,  
      { email: session.user?.email, name: session.user.full_name }
    )
    return <Redirect to="/catering" />
  }

  return (
    <div className="min-h-screen flex items-center justify-center px-4">
      <div className="w-full max-w-md">
        <div className="bg-white px-6 md:px-10 py-16 rounded shadow overflow-hidden">
          <form onSubmit={handleSubmit}>
            <h1 className="font-platform text-4xl text-navy text-center leading-tight">
              Welcome!
            </h1>
            <p className="text-center text-gray-800 font-light text-lg mb-8">
              Please sign in to continue.
            </p>
            <div className="mb-5">
              <input
                type="text"
                placeholder="Email"
                className="input input-lg w-full"
                value={fields.email}
                onChange={e => {
                  setFields({ ...fields, email: e.target.value })
                }}
              />
              {errors?.email && (
                <ErrorMessage message={`Email ${errors.email[0].message}`} />
              )}
            </div>
            <div className="mb-6">
              <input
                type="password"
                placeholder="Password"
                className="input input-lg w-full"
                value={fields.password}
                onChange={e => {
                  setFields({ ...fields, password: e.target.value })
                }}
              />
              {errors?.password && (
                <ErrorMessage message={`Password ${errors.password[0].message}`} />
              )}
            </div>
            {errors?.account && (
              <ErrorMessage
                className="-mt-3 mb-5"
                message="Sorry, this login does not appear to be associated with an active vendor account."
              />
            )}
            <Submit
              type="submit"
              className="w-full py-3 rounded-sm text-lg mb-8"
              children="Submit"
              isLoading={isLoading}
            />
            <div className="text-center">
              <TrackableLink to="/forgot-password" className="font-bold text-blue hover:text-navy">
                Forgot your password?
              </TrackableLink>
            </div>
          </form>
        </div>
      </div>
      <div className="fixed bottom-0 mb-2 text-center text-gray-600 text-xs">
        Version: {env.APP_VERSION || 'n/a'}
      </div>
    </div>
  )
}

function ErrorMessage(props) {
  return (
    <div className={['text-red-400 mt-1 ml-1', props.className]}>
      {props.message}
    </div>
  )
}
