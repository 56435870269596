import React, { useState, useEffect } from 'react'
import env from 'env'

import { motion as Motion, AnimatePresence } from 'framer-motion'
import { AlertCircle, XCircle } from 'react-feather'

import styles from 'app/theme/VersionCheck.module.css'

export default function VersionCheck() {
  const [appVersion, setAppVersion] = useState(env.APP_VERSION)
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {

    if (!env.APP_VERSION) {
      // bail if there is no locally-set app version
      return
    }

    // runs every five minutes
    const fetchVersion = setInterval(() => {
      fetch('/version')
        .then(response => response.json())
        .then(resolved => {
          if (resolved.version !== appVersion) {
            setShowWarning(true)
          }
        })
        .catch(err => console.log(err))
    }, 5 * 60 * 1000)
    return () => {
      clearInterval(fetchVersion)
    }
  },[])

  if (!showWarning) {
    return null
  }

  return (
    <div className={[styles.notification_container]}>
      <AnimatePresence initial={false}>
        <Motion.div
          positionTransition
          initial={{ opacity: 0, y: -50, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -20, scale: 0.9, transition: { duration: 0.2 } }}
        >
          <div className={styles.notification}>
            <div className="flex items-center px-4 border-r flex-shrink-0">
              <AlertCircle size={30} />
            </div>
            <div className="flex-1 p-4">
              <div className="flex items-center">
                <div className="text-xl leading-none mb-1 flex-grow">
                  App version out of date
                </div>
                <XCircle size={20}  className="cursor-pointer" onClick={() => setShowWarning(false)}/>
              </div>
              <div className="leading-tight mt-2">
                Your app version is out of date! Refresh the page to update.
              </div>
              <div className="w-full mt-3">
                <button 
                  className="btn border border-white rounded py-1 px-4 cursor-pointer"
                  onClick={() => window.location.reload(true)}
                >
                  Refresh
                </button>
                <button 
                  className="btn py-1 text-red-200 px-4 cursor-pointer"
                  onClick={() => setShowWarning(false)}
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </Motion.div>
      </AnimatePresence>
    </div>
  )
}