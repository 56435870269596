import React from 'react'
import posthog from 'posthog-js'

const TrackableButton = ({ to, children, title, onClick, type = 'button', ...props }) => {
  const buttonText =
    title || (typeof children === 'string' ? children : 'Non-text button')

  const handleClick = event => {
    // Prevent default behavior
    event.preventDefault()


    // Track the click event in PostHog
    posthog.capture('cta_button_clicked', { path: to, buttonText: buttonText })

    // Call the onClick prop if it exists
    if (onClick) {
      onClick(event)
    }
  }

  return (
    <button type={type} onClick={handleClick} {...props}>
      {children}
    </button>
  )
}

export default TrackableButton
