import React, { useState } from 'react'
import { useSession } from 'app/session'
import { AlertCircle } from 'react-feather'
import { useLocation, useHistory } from 'react-router-dom'

export default function ExpiredDocumentsAlert() {
  const session = useSession()
  const location = useLocation()
  const history = useHistory()

  if (!session?.caterer?.has_expired_coi || location.pathname === '/settings/documents') {
    return null
  }

  return (
    <div className="bg-red text-white">
      <div className="container lg:flex py-5">
        <div className="flex-1 leading-none">
          <div className="flex items-center mb-2">
            <AlertCircle
              className="mr-2" size={20}
            />
            <div className="text-2xl">
              You have expired documents
            </div>
          </div>
          <div className="font-light">
            If you want to continue to be booked with Mobile Food Alliance events, please update your documents.
          </div>
        </div>
        <div className="mt-6 lg:mt-0 lg:pl-6">
          <button
            children="View Documents"
            className={[
              'btn border border-white text-lg px-8 py-2',
              'w-full md:w-auto',
              'hover:bg-white hover:text-red-400',
            ]}
            onClick={() => {
              history.push('/settings/documents')
            }}
          />
        </div>
      </div>
    </div>
  )
}
