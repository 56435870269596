import React from 'react'

export default function Checkbox(props) {
  const {
    label = null,
    size,
    disabled,
    checked,
    defaultChecked,
    onChange,
    className,
    ...rest
  } = props

  return (
    <label className={[className, 'checkbox', size && `checkbox-${size}`]}>
      <input
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={event => {
          onChange && onChange(event.target.checked, event)
        }}
      />
      <span>{label}</span>
    </label>
  )
}
