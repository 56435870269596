import React, { createContext, useMemo, useEffect, useRef } from 'react'

export const PostHogContext = createContext({})

export function PostHogProvider({
  client,
  children,
}) {
  const loadedRef = useRef(false)

  const value = useMemo(() => ({
    posthog: client,
    onFeatureFlags: (callback, autoLoad = true) => {
      if (autoLoad && !loadedRef.current) {
        loadedRef.current = true
        client.reloadFeatureFlags()
      }
      return client.onFeatureFlags(() => {
        callback()
      })
    },
  }), [
    client,
  ])

  useEffect(() => {
    return client.onFeatureFlags(() => {
      loadedRef.current = true
    })
  }, [client])

  return (
    <PostHogContext.Provider
      value={value}
      children={children}
    />
  )
}
