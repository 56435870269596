import React from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'

const IS_CLIENT = typeof window !== 'undefined'
const DEFAULT_STORAGE_KEY = 'feature_flags'

export const FeatureFlagContext = React.createContext({})

export function FeatureFlagProvider(props) {
  const location = useLocation()

  const [flags, setFlags] = React.useState(() => {
    let params = parse(location.search.substr(1))

    if (params.enable_features) {
      params.enable_features = params.enable_features.split(',').reduce((a, i) => ({ ...a, [i.trim()]: true }), {})
    }

    if (params.disable_features) {
      params.disable_features = params.disable_features.split(',').reduce((a, i) => ({ ...a, [i.trim()]: false }), {})
    }

    let flags = {
      ...props.flags,
    }

    if (IS_CLIENT) {
      try {
        const item = window.sessionStorage.getItem(props.storageKey || DEFAULT_STORAGE_KEY)
        if (item !== null) {
          flags = { ...flags, ...JSON.parse(item) }
        }
      } catch (e) {}
    }

    return {
      ...flags,
      ...params.disable_features,
      ...params.enable_features,
    }
  })

  const value = React.useMemo(() => [flags, setFlags], [flags])

  React.useEffect(() => {
    try {
      window.sessionStorage.setItem(props.storageKey || DEFAULT_STORAGE_KEY, JSON.stringify(flags))
    } catch (e) {}
  }, [flags])

  return (
    <FeatureFlagContext.Provider
      value={value}
      children={props.children}
    />
  )
}

export function useFeatureFlags() {
  return React.useContext(FeatureFlagContext)
}
