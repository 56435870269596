import React from 'react'

export default function ProgressBar({ progress = 0, title }) {
  return (
    <div>
      {title && (
        <div className="text-gray-600 text-right mb-1 font-platform font-light">
          {title}
        </div>
      )}
      <div
        className="h-2 mx-auto rounded-full bg-gray-300 overflow-hidden relative"
      >
        <div
          style={{ width: progress + '%' }}
          className="bg-blue absolute inset-0 transition-all duration-500"
        />
      </div>
    </div>
  )
}
