import React from 'react'

export default function TwoColumns(props) {
  return (
    <div
      className={[
        'lg:grid lg:grid-cols-3 lg:gap-12',
        props.className,
      ]}
    >
      <div
        children={props.children}
        className={[
          'mb-8 lg:mb-0 lg:col-span-2'
        ]}
      />
      <div
        children={props.sidebar}
        className={[
          'lg:col-span-1'
        ]}
      />
    </div>
  )
}
