import React, { useState } from 'react'
import { useSession } from 'app/session'
import { AlertCircle } from 'react-feather'
import CateringAgreementModal from 'app/agreements/CateringAgreementModal'
import { useNotify } from 'app/theme'

export default function CateringAgreementAlert() {
  const session = useSession()
  const notify = useNotify()

  const [showModal, setShowModal] = useState(false)

  if (!session.isLoggedIn || ['current', 'valid'].includes(session?.caterer?.agreement_status)) {
    return null
  }

  return (
    <div className="bg-red text-white">
      <div className="container lg:flex py-5">
        <div className="flex-1 leading-none">
          <div className="flex items-center mb-2">
            <AlertCircle
              className="mr-2" size={20}
            />
            <div className="text-2xl">
              We've updated our terms and conditions
            </div>
          </div>
          <div className="font-light">
            If you want to continue to be booked with Mobile Food Alliance events, please accept our updated terms and conditions.
          </div>
        </div>
        <div className="mt-6 lg:mt-0 lg:pl-6">
          <button
            children="View Terms"
            className={[
              'btn border border-white text-lg px-8 py-2',
              'w-full md:w-auto',
              'hover:bg-white hover:text-red-400',
            ]}
            onClick={() => {
              setShowModal(true)
            }}
          />
        </div>
      </div>
      <CateringAgreementModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        caterer={session.caterer}
        allowAccept
        onAccept={() => {
          session.refresh()
          notify.show({
            theme: 'success',
            title: 'Success',
            message: 'Agreement was accepted!'
          })
        }}
      />
    </div>
  )
}
