export default {
  fields: {
    caterer_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    caterer: 'caterers',
    category: 'menu_categories',
    photo: 'photos',
  },
}
