import 'react-app-polyfill/ie11'
import React from 'react'
import { hydrate } from 'react-dom'
import { loadableReady } from '@loadable/component'
import { BrowserRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { CookieProvider } from 'lib/cookies'
import { ApiProvider, ApiClient } from 'jsonapi-react'
import App from 'app/App'
import createClient from './app/createClient'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { PostHogProvider } from 'lib/posthog' 
import env from 'env'

let cookies = new Cookies()

let posthogBootstrap
if (window.__APP_STATE__?.posthog) {
  posthogBootstrap = {
    ...window.__APP_STATE__.posthog,
    distinctID: cookies.get(UID_COOKIE_NAME),
  }
}

if (typeof window !== 'undefined') {
  posthog.init(
    env.POSTHOG_TOKEN, 
    {
      api_host: env.POSTHOG_HOST,
      capture_pageview: true,
      capture_pageleave: false,
      autocapture: false,
      disable_session_recording: true
    }
  )
}

if (env.SENTRY_DSN && env.APP_ENV !== 'development') {
  Sentry.init({
    dsn: env.SENTRY_DSN,
    environment: env.APP_ENV,
    release: env.APP_VERSION,
    ignoreErrors: [
      'Object Not Found Matching Id'
    ],
    ignoreUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ]
  })
}

const state = window.__APP_STATE__ || {}

const client = createClient({ url: state.env.API_URL })
client.hydrate(state.api)

const Root = (
  <Sentry.ErrorBoundary showDialog>
    <CookieProvider value={cookies}>
      <ApiProvider client={client}>
        <BrowserRouter>
          <PostHogProvider client={posthog}>
            <App />
          </PostHogProvider>
        </BrowserRouter>
      </ApiProvider>
    </CookieProvider>
  </Sentry.ErrorBoundary>
)

loadableReady(() => {
  hydrate(Root, document.getElementById('root'))
})

if (module.hot) {
  module.hot.accept()
}
