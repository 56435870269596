import './theme/styles'

import React, { useEffect, useRef } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import LoadingScreen from './theme/LoadingScreen'
import { Layout } from './theme/layout'
import { useCreateSession, SessionContext } from './session'
import { NotificationProvider } from 'app/theme/Notification'
import routes from './routes'
import useEventTracker from 'lib/useEventTracker'
import env from 'env'
import { FeatureFlagProvider } from 'app/utils/feature-flags'
import VersionCheck from './theme/VersionCheck'
import config from 'app/config'

export default function App(props) {
  const session = useCreateSession()
  const tracker = useEventTracker()
  const location = useLocation()

  useEffect(() => {
    tracker.pageView(location.pathname)
    if (!location.state || location.state.scrollTop !== false) {
      window.scrollTo(0, 0)
    }
  }, [location.pathname, location.search])

  return (
    <SessionContext.Provider value={session}>
      <NotificationProvider>
        <FeatureFlagProvider flags={config.featureFlags}>
          <Switch>
            {routes.gated.map((route) => (
              <Route
                key={route.path}
                exact={route.exact !== false}
                path={route.path}
                children={props => (
                  session.isLoading ? (
                    <LoadingScreen />
                  ) : !session.isLoggedIn ? (
                    <LoginPage {...props} session={session} />
                  ) : (
                    <route.component {...props} session={session} />
                  )
                )}
              />
            ))}
            {routes.ungated.map((route) => (
              <Route
                key={route.path}
                exact={true}
                path={route.path}
                children={props => (
                  <route.component {...props} session={session} />
                )}
              />
            ))}
          </Switch>
        </FeatureFlagProvider>
        <VersionCheck />
      </NotificationProvider>
    </SessionContext.Provider>
  )
}
