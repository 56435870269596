export default {
  fields: {
    uuid: {
      readOnly: true,
    },
    first_service_date: {
      type: 'date',
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    tags: 'tags',
    good_fors: 'good_fors',
    menu_items: 'menu_items',
    menu_categories: 'menu_categories',
    services: 'caterer_services',
    photos: 'photos',
    packages: 'packages',
    documents: 'documents',
    reviews: 'reviews',
    story: 'caterer_stories',
    settings: 'caterer_settings',
    agreements: 'caterer_agreements',
    memberships: 'memberships',
    users: 'users',
    proposals: 'proposals',
    bookings: 'bookings',
    leads: 'leads',
    clients: 'caterer_clients',
    calendars: 'calendars',
    calendar_events: 'calendar_events',
    cities: 'cities',
    caterer_cities: 'caterer_cities',
  },
}
