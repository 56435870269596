export default {
  fields: {
    caterer_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    caterer: 'caterers',
    categories: 'package_categories',
    items: 'package_items',
    photo: 'photo',
    caterer_services: 'caterer_services',
  },
}
