export default {
  fields: {
    package_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    package: 'packages',
    items: 'package_items',
  },
}
