export default {
  fields: {
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    leads: {
      type: 'leads',
    },
    proposals: {
      type: 'proposals',
    },
  },
}
