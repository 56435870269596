import React from 'react'
import truck from 'assets/img/burger-truck.gif'

export default function LoadingScreen() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <img src={truck} style={{ maxWidth: 200 }} />
    </div>
  )
}
