export default {
  fields: {
    proposal_id: {
      readOnly: true,
    },
    booking_id: {
      readOnly: true,
    },
    caterer_subtotal: {
      readOnly: true,
    },
    caterer_fees: {
      readOnly: true,
    },
    caterer_total: {
      readOnly: true,
    },
    service_fees: {
      readOnly: true,
    },
    transaction_fees: {
      readOnly: true,
    },
    sales_tax: {
      readOnly: true,
    },
    grand_total: {
      readOnly: true,
    },
    paid_total: {
      readOnly: true,
    },
    balance: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    proposal: 'proposals',
    booking: 'bookings',
    fees: 'fees',
  },
}
