export default {
  fields: {
    uuid: {
      readOnly: true,
    },
    status: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    caterer_service_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    caterer: {
      type: 'caterers',
    },
    caterer_service: {
      type: 'caterer_services',
    },
  }
}
