import React from 'react'
import Modal from './Modal'
import { AlertTriangle } from 'react-feather'
import Spinner from './Spinner'

export default function ConfirmModal(props) {
  const {
    isOpen = true,
    onCancel = () => {},
    closeModal = onCancel,
    isLoading,
    onConfirm,
    title = 'Confirmation',
    message = 'Please confirm you want to take this action',
    maxWidth = 'max-w-lg',
  } = props

  return (
    <Modal
      isOpen={isOpen}
      className={['p-8 w-full', maxWidth]}
      closeModal={closeModal}
    >
      <div className="">
        <div className={[
          'w-12 h-12 rounded-full flex items-center justify-center',
          'bg-red-200 text-red-600 mx-auto mb-3'
        ]}>
          <AlertTriangle />
        </div>
        <div className="leading-snug text-center mb-6">
          <h3 className="font-bold text-2xl">
            {title}
          </h3>
          <p className="text-gray-700 mt-2 font-light leading-snug">
            {message}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <button
          children="Cancel"
          className="btn btn-gray py-2"
          onClick={onCancel}
        />
        <button
          className="btn btn-red py-2 relative"
          onClick={onConfirm}
          children={isLoading ? <Spinner color="white" className="text-xs mx-auto" /> : 'Confirm'}
        />
      </div>
    </Modal>
  )
}
