export default {
  fields: {
    booking_id: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    package_id: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    photo: 'photos',
    caterer: 'caterers',
    booking: 'bookings',
    package: 'packages',
    items: 'booking_package_items',
    categories: 'booking_package_categories',
  },
}
