import { useState, useEffect } from 'react'

const isClient = typeof window !== 'undefined'

export default function useSessionStorage(key, initialValue) {
  const [state, setState] = useState(() => {
    if (typeof initialValue === 'function') {
      initialValue = initialValue()
    }

    if (!isClient) {
      return initialValue
    }

    try {
      const item = window.sessionStorage.getItem(key)
      return item !== null ? JSON.parse(item) : initialValue
    } catch (e) {
      return initialValue
    }
  })

  const remove = () => {
    setState(undefined)
  }

  useEffect(() => {
    try {
      if (state === undefined) {
        window.sessionStorage.removeItem(key)
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(state))
      }
    } catch (e) {}
  }, [state])

  return [
    state,
    setState,
    remove,
  ]
}
