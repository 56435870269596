import { createContext, useContext, useState, useEffect } from 'react'

export const CookieContext = createContext()

export const CookieProvider = CookieContext.Provider

export function useCookie(key, defaultOptions) {
  const cookies = useContext(CookieContext)

  const [value, setValue] = useState(() => {
    return cookies.get(key, defaultOptions)
  })

  useEffect(() => {
    function handleChange(update) {
      if (update.name === key) {
        setValue(update.value)
      }
    }

    cookies.addChangeListener(handleChange)

    return () => {
      cookies.removeChangeListener(handleChange)
    }
  }, [key])

  const setCookie = (value, options) => {
    cookies.set(key, value, {
      ...defaultOptions,
      ...options,
    })
  }

  const removeCookie = options => {
    cookies.remove(key, {
      ...defaultOptions,
      ...options,
    })
  }

  return [value, setCookie, removeCookie]
}
