import { useState, useMemo, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

const getNext = (next, prev) => (
  typeof next === 'function' ? next(prev) : next
)

export const stringify = (params, extra) => {
  params = JSON.stringify(params, (k,v) => {
    if (v === '') {
      return undefined
    }
    if (Array.isArray(v) && !v.length) {
      return undefined
    }
    return v
  })
  return qs.stringify({ ...extra, params }, {
    encode: false,
  })
}

export default function useQueryParams(defaultParams) {
  const mountedRef = useRef()

  const history = useHistory()

  const query = useMemo(() => (
    qs.parse(history.location.search.substr(1))
  ), [history.location.search])

  const params = useMemo(() => {
    return query.params ? JSON.parse(query.params) : (defaultParams || {})
  }, [query.params])

  const stringifyParams = params => {
    return stringify(params, query)
  }

  const setParams = (value, scrollTop) => {
    history.push(`?${stringifyParams(getNext(value, params))}`, { scrollTop })
  }

  useEffect(() => {
    mountedRef.current = true
  }, [])

  return [
    params,
    setParams,
  ]
}
