import React from 'react'
import Tippy from '@tippyjs/react'

import 'tippy.js/dist/tippy.css'

export default function Tooltip(props) {

  return (
    <Tippy
      arrow={true}
      theme="blue"
      trigger="mouseenter"
      placement="top"
      duration={0}
      {...props}
    >
      {props.children}
    </Tippy>
  )
}
