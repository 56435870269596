import React from 'react'
import Spinner from '../Spinner'

export default function Submit(props) {
  const {
    color = 'blue',
    spinnerColor = 'white',
    children,
    className,
    divClassName,
    isLoading,
    disabled,
    text,
    ...rest
  } = props

  return (
    <div className={divClassName}>
      <button
        type="submit"
        {...rest}
        disabled={disabled || isLoading}
        className={[
          'btn flex items-center justify-center',
          color && `btn-${color}`,
          className,
          disabled && 'opacity-50',
        ]}
        >
        {isLoading && (
          <Spinner height="1.5em" color={spinnerColor} className="absolute" />
        )}
        <span className={[isLoading && 'opacity-0']}>
          {text || children}
        </span>
      </button>
    </div>
  )
}
