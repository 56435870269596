export default {
  fields: {
    payable_id: {
      readOnly: true,
    },
    payable_type: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    payable: {
      polymorphic: true,
    }
  }
}
