import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import posthog from 'posthog-js'

const TrackableNavLink = ({ to, children, title, ...props }) => {
  const history = useHistory()

      // Determine the link text for tracking
  const linkText =
    title || (typeof children === 'string' ? children : 'Non-text link')

  const handleClick = event => {
    // Prevent default behavior
    event.preventDefault()

    // Track the click event in PostHog
    posthog.capture('nav_link_clicked', { path: to, linkText })

    // Use history.push to navigate after tracking
    history.push(to)
  }

  return (
    <NavLink to={to} onClick={handleClick} {...props}>
      {children}
    </NavLink>
  )
}

export default TrackableNavLink
