export default {
  fields: {
    uuid: {
      readOnly: true,
    },
    path: {
      readOnly: true,
    },
    extension: {
      readOnly: true,
    },
    caterer_id: {
      readOnly: true,
    },
    approved_at: {
      type: 'date',
    },
    expires_at: {
      type: 'date',
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
  relationships: {
    caterer: 'caterers',
  }
}
