import env from '../../env'

export default {
  fields: {
    subject_id: {
      readOnly: true,
    },
    subject_type: {
      readOnly: true,
    },
    created_at: {
      readOnly: true,
      type: 'date',
    },
    updated_at: {
      readOnly: true,
      type: 'date',
    },
    path: {
      readOnly: true,
    },
    url: {
      readOnly: true,
      resolve: (val, attrs) => (
        attrs.path ? `${env.MEDIA_URL}/${attrs.path}` : null
      )
    },
  },
  relationships: {
    subject: {
      polymorphic: true,
    }
  }
}
