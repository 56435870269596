export default {
  fields: {
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    promotion_accounts: 'promotion_accounts',
  },
}
