import React from 'react'
import loadable, { lazy } from '@loadable/component'
import { Redirect } from 'react-router-dom'
import { Layout } from './theme/layout'

const ungated = [
  {
    path: '/',
    // component: loadable(() => import('./pages/CateringLeadsHomePage')),
    component: () => <Redirect to="/marketplace/" />,
  },
  {
    path: '/get-started',
    component: loadable(() => import('./pages/GetStartedPage')),
  },
  {
    path: '/login',
    component: loadable(() => import('./pages/LoginPage')),
  },
  {
    path: '/logout',
    component: loadable(() => import('./pages/LogoutPage')),
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('./pages/ForgotLoginPage')),
  },
  {
    path: '/reset-password',
    component: loadable(() => import('./pages/ResetPasswordPage')),
  },
  {
    path: '/marketplace/',
    component: loadable(() => import('./marketplace/MarketplacePage')),
  },
  {
    path: '/start-a-food-truck/',
    component: loadable(() => import('./marketplace/StartTruckPage')),
  },
  {
    path: '/marketplace/add/',
    component: loadable(() => import('./marketplace/AddListingPage')),
  },
  {
    path: '/marketplace/faq/',
    component: loadable(() => import('./marketplace/MarketplaceFAQPage')),
  },
  {
    path: '/marketplace/buy-a-food-truck/listing/:slug([\\w+-]+)/:token(\\w{32})/:step?',
    component: loadable(() => import('./marketplace/ListingDepositPage')),
  },
  {
    path: '/marketplace/buy-a-food-truck/listing/:slug([\\w+-]+)',
    component: loadable(() => import('./marketplace/ListingPage')),
  },
  {
    path: '/marketplace/buy-a-food-truck/:placeSlug([-a-z-]+)?/:vehicleType([a-z-]{4,})?/:page([0-9]+)?',
    component: loadable(() => import('./marketplace/BuyTruckRouter')),
  },
  {
    path: '/marketplace/build-a-food-truck/',
    component: loadable(() => import('./marketplace/BuildTruckPage')),
  },
  {
    path: '/marketplace/lease-a-food-truck/',
    component: loadable(() => import('./marketplace/LeaseTruckPage')),
  },
  {
    path: '/marketplace/sell-a-food-truck/',
    component: loadable(() => import('./marketplace/SellTruckPage')),
  },
  {
    path: '/catering-leads/',
    component: loadable(() => import('./pages/CateringLeadsHomePage')),
  },
  {
    path: '/blog/',
    component: loadable(() => import('./blog/BlogPage')),
  },
  {
  path: '/blog/page/:page?',
    component: loadable(() => import('./blog/BlogPage')),
  },
  {
    path: '/blog/category/:category?',
    component: loadable(() => import('./blog/BlogPage')),
  },
  {
    path: '/blog/category/:category?/page/:page?',
    component: loadable(() => import('./blog/BlogPage')),
  },
  {
    path: '/blog/:slug([\\w+-]+)',
    component: loadable(() => import('./blog/PostPage')),
  },
  {
    path: '*',
    component: () => <Redirect to="/" />,
  },
]

const gated = [
  {
    path: '/catering/:bookingId(\\d+)?',
    component: () => import('./pages/CateringPage'),
  },
  {
    path: '/public-events/:bookingId(\\d+)?',
    component: () => import('./pages/VendingPage'),
  },
  {
    path: '/calendar',
    component: () => import('./pages/CalendarPage'),
  },
  /* {
    path: '/promotions',
    component: () => import('./pages/PromotionsPage'),
  }, 
  {
    path: '/promotions/:promotionCode/:step?',
    component: () => import('./pages/PromotionPage'),
  },*/
  {
    path: '/settings/*',
    component: () => import('./pages/SettingsPage'),
  },
  {
    path: ['/account', '/account/*'],
    component: () => import('./pages/AccountPage'),
  },
  {
    path: '/google/callback',
    component: () => import('./pages/calendar/GoogleCallback'),
  },
].map(route => ({
  ...route,
  component: loadable(route.component, {
    fallback: <Layout isLoading />,
  })
}))

export default {
  gated,
  ungated,
}
