export default {
  fields: {
    uuid: {
      readOnly: true,
    },
    last_login: {
      type: 'date',
      readOnly: true,
    },
    full_name: {
      readOnly: true,
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    }
  },
  relationships: {
    proposals: {
      type: 'proposals',
    },
    memberships: {
      type: 'memberships',
    }
  },
}
