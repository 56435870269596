import { useRef, useEffect } from 'react'
import env from 'env'

export default function useEventTracker(key, initialValue) {
  const ref = useRef()

  useEffect(() => {
    ref.current = window.gtag
  }, [])

  return {
    pageView(page_path) {
      if (ref.current) {
        return ref.current('config', env.GA_TRACKING_ID, {
          page_path,
        })
      } else {
        return null
      }
    },
    event(action, params) {
      return ref.current ? ref.current('event', action, params) : null
    }
  }
}
