import { useCookie } from 'lib/cookies'

export default function useLoginToken() {
  const [token, set, remove] = useCookie('authorization', {
    path: '/',
  })

  const setToken = (value, options) => {
    set(value, options)
  }

  const removeToken = options => {
    remove(name, options)
  }

  return [
    token,
    setToken,
    removeToken,
  ]
}
