import { useRef, useState, useEffect } from 'react'

export default function useDropDown() {
  const ref = useRef()
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const onClick = ({ target, defaultPrevented }) => {
      if (
        ref.current &&
        !ref.current.contains(target) &&
        !defaultPrevented
      ) {
        setOpen(false)
      }
    }

    if (isOpen) {
      document.addEventListener('click', onClick)
    }

    return () => {
      if (isOpen) {
        document.removeEventListener('click', onClick)
      }
    }
  }, [isOpen])

  return {
    ref,
    isOpen,
    setOpen,
  }
}
