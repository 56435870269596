export default {
  fields: {
    effective_date: {
      type: 'date',
    },
    created_at: {
      type: 'date',
      readOnly: true,
    },
    updated_at: {
      type: 'date',
      readOnly: true,
    },
  },
}
